class Cache {
  public storage: Storage

  public constructor(isLocal = true) {
    this.storage = isLocal ? localStorage : sessionStorage
  }

  /**
   * @description: 设置缓存
   * @param { String } key  键
   * @param { unknown } value 值
   */
  public setItem(key: string, value: unknown) {
    if (typeof value === 'object') {
      this.storage.setItem(key, JSON.stringify(value, this.getCircularReplacer()))
    } else {
      this.storage.setItem(key, value as string)
    }
  }

  /**
   * @description: 获取缓存
   * @param { String } key  键
   */
  public getItem(key: string) {
    try {
      const data = this.storage.getItem(key)
      if (data) {
        return JSON.parse(data)
      }
      return data
    } catch (err) {
      return this.storage.getItem(key)
    }
  }

  /**
   * @description: 删除缓存
   * @param { String } key  键
   */
  public removeItem(key: string) {
    this.storage.removeItem(key)
  }

  /**
   * @description: 清空缓存
   */
  public clear() {
    this.storage.clear()
  }

  /**
   * @description: 获取键
   * @param { Number } index  下标
   */
  public key(index: number) {
    return this.storage.key(index)
  }

  /**
   * @description: 获取长度
   */
  public length() {
    return this.storage.length
  }

  /**
   * @description: 获得循环置换器
   */
  private getCircularReplacer() {
    const seen = new WeakSet()
    // eslint-disable-next-line @typescript-eslint/ban-types
    return (_key: unknown, value: object | null) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return
        }
        seen.add(value)
      }
      return value
    }
  }
}

const localCache = new Cache()

const sessionCache = new Cache(false)

export { localCache, sessionCache }
